import validate from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/node_modules/nuxt/dist/pages/runtime/validate.js";
import auth_45global from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/middleware/auth.global.js";
import import_45global from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/middleware/import.global.js";
import permissions_45global from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/middleware/permissions.global.js";
import workflow_45global from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/middleware/workflow.global.js";
import manifest_45route_45rule from "/var/lib/go-agent2/pipelines/BuildV2SendxAPPFrontend/go/src/github.com/thebitmonk/sendx/sendx/app_frontend2/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  auth_45global,
  import_45global,
  permissions_45global,
  workflow_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {}